import React, { Component } from 'react';
import './App.css';
import {
	BrowserRouter as Router,
	Route,
} from 'react-router-dom';
import Navbar from './common/components/Navbar';
import Transitional from './common/components/Transitional';
import AsyncComponent from './common/components/AsyncComponent';
const importComponent = component => AsyncComponent(() => import(`./${component}.js`));
const routes = [
	{
		text: "Home",
		title: "Home",
		path:"/",
		exact: true,
		Component: importComponent("home/Home"),
	},
	{
		text: "Portfolio",
		title: "Portfolio",
		path:"/projects",
		Component: importComponent("projects/Projects"),
		onClick:()=>{window.location.hash=""},
		hideFromNavbar: true,
	},
	{
		text: "Commissions",
		title: "Commissions",
		path:"/commissions",
		Component: importComponent("commissions/Commissions"),
	},
	// {
	// 	text: "Discord",
	// 	href: "http://discord.dragonwolfleo.com",
	// },
];
const validRoutes = new Set(routes.map(({path})=>path));
const navbarEnabled = true;
class App extends Component {
	loadedComponents = []
	setDocumentTitle = () => {
		for(let i = 0; i < routes.length; i++){
			if(routes[i].path === this.activePage){
				document.title = `${routes[i].title || "Not Found"} - Dragon Wolf Leo's Website`;
			}
		}
	}
	render() {
		return (
			<Router>
				<div>
					{navbarEnabled && <Navbar routes={routes} />}
					{
						<Route
							path="/"
							render={({location: {pathname}})=>{
								if(!validRoutes.has(pathname)){
									pathname="/";
								}
								if(this.activePage && this.activePage !== pathname){
									this.prevPage = this.activePage;
									this.activePage = pathname;
									this.setDocumentTitle();
									clearTimeout(this.transitiontask);
									this.transitiontask = setTimeout(()=>{
										this.prevPage = null;
										this.forceUpdate();
									},300);
								}else if(!this.activePage){
									this.activePage = pathname;
									this.setDocumentTitle();
								}
								return <div>
									{
										routes.map(({Component, path: route},i)=>{
										const loaded = this.loadedComponents[i];
										const active = pathname===route;
										if(active && !loaded) this.loadedComponents[i] = true;
										return (route && (active || loaded)) 
										&& <Transitional
											key={route}
											active={active}
											transitioning={active || this.prevPage===route}
										>
											<Component 
												active={active} 
												navbarDisplacement={navbarEnabled}
											/>
										</Transitional>})
									}
								</div>
							}}
						/>
					}
				</div>
			</Router>
		);
	}
}

export default App;
