import React from "react";
import {Link} from "react-router-dom";
import "../css/Navbar.css";
const scrollToTop = () => {
	document.documentElement.scrollTop = 0;
}

const Navbar = ({routes}) => {
	return routes ? <div>
		<nav className="pfnavbar">
			<ul className="nav ml-md-5">
				{routes.map((route,i)=>{
					if (route.hideFromNavbar) return null;
					return <li key={i} className="nav-item">
						{route.path ?
							<Link 
								className="nav-link" 
								to={route.path} 
								onClick={(...event)=>{
									route.onClick && route.onClick(...event);
									scrollToTop();
								}}>
									{route.text}
							</Link>
						:
						<a className="nav-link" 
							href={route.href}
							target="_blank"
							rel="noopener noreferrer"
						>{route.text}</a>
						}
					</li>}
				)}
			</ul>
		</nav>
		<div style={{
			height: "40px",
			position: "relative",
		}}>

		</div>
	</div> : null;
	
}

export default Navbar;